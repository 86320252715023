import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sw: false,
  },
  mutations: {
    gai(state, val) {
      state.sw = val;
    },
  },
  actions: {},
  modules: {},
});
