<template>
  <div class="home">
    <div class="pc">
      <div class="banner">
        <el-carousel trigger="click" height="34vw" :interval="7000">
          <el-carousel-item>
            <img class="img1" src="img/zbj.png" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img class="img2" src="img/zbj2.png" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img class="img3" src="img/zbj3.png" alt="" />
          </el-carousel-item>
        </el-carousel>
        <div class="mask">
          <div class="box">
            <h3 v-show="!this.$store.state.sw">
              Total Precision <br />
              Injection Solutions <br />
              <br />
            </h3>
            <h3 v-show="this.$store.state.sw">
              我們專業從事 <br />
              精密塑膠注塑產品 <br />
              的開發和製造
            </h3>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="module1">
          <h3 style="padding: 2vw 0 0vw 2vw" v-show="!this.$store.state.sw">
            Solutions
          </h3>
          <h3 style="padding: 2vw 0 0vw 2vw" v-show="this.$store.state.sw">
            解決方案
          </h3>
          <div class="box">
            <div
              @click="
                () => {
                  this.in1 = !this.in1;
                  this.in3 = false;
                  this.in2 = false;
                }
              "
              :class="this.in1 ? 'k' : 'g'"
              :style="
                this.in1 == false && (this.in2 || this.in3)
                  ? 'background-color:rgb(184, 183, 183) !important'
                  : ''
              "
            >
              <h3 v-if="!this.$store.state.sw">
                Precision Plastic Mold Design and Manufacturing
              </h3>
              <h3 v-if="this.$store.state.sw" class="t2">精密塑膠模具設計</h3>
              <div
                :style="
                  this.in1 == false && (this.in3 || this.in2)
                    ? 'display:none !important;text-angin:center !important'
                    : ''
                "
              ></div>
              <img src="img/cc1.png" alt="" />
              <p
                v-if="!this.$store.state.sw"
                :style="
                  this.in1 == false && (this.in3 || this.in2)
                    ? 'display:none !important'
                    : ''
                "
              >
                Whether it is a mold that requires high mold temperature molding
                or extremely tight tolerance molding.
                <span v-if="this.in1">
                  KENTA can provide solutions to meet all your needs for
                  precision plastic molds. Our capabilities include (not limited
                  to fast and small molds with low product demand, or
                  large-scale multi-cavity molds with high demand, and large
                  composite molds such as stack molds, such as engineering test
                  mold design, 3D printing, high mold temperature molding molds,
                  and extremely tight tolerances. Molding molds, overmolding
                  molds and insert molds, DFM, DOE, etc.</span
                >
              </p>
              <p
                v-if="this.$store.state.sw"
                :style="
                  this.in1 == false && (this.in3 || this.in2)
                    ? 'display:none !important'
                    : ''
                "
              >
                無論是需求高模溫成型還是極嚴公差成型的模具。
                <span v-if="this.in1">
                  健大都能提供滿足您所有精密塑膠模具所需求的解決方案。我們的能力包括（不限於產品需求量低的快速小型模具或者需求量大的多穴數以至疊模等大型複合模具，如工程試驗模具設計，3D打印，高模溫成型模具，需求極嚴公差成型模具，包膠模和嵌件模等模具，DFM，DOE）等</span
                >
              </p>
              <br /><br /><br />
            </div>

            <div
              @click="
                () => {
                  this.in2 = !this.in2;
                  this.in1 = false;
                  this.in3 = false;
                }
              "
              :class="this.in2 ? 'k' : 'g'"
              :style="
                this.in2 == false && (this.in1 || this.in3)
                  ? 'background-color:rgb(184, 183, 183) !important'
                  : ''
              "
            >
              <h3 v-if="!this.$store.state.sw">
                Plastic Component Injection Molding
              </h3>
              <h3 v-if="this.$store.state.sw" class="t2">插針及鑲嵌包膠注塑</h3>
              <div
                :style="
                  this.in2 == false && (this.in1 || this.in3)
                    ? 'display:none !important'
                    : ''
                "
              ></div>
              <img src="img/cc2.png" alt="" />
              <p
                v-if="!this.$store.state.sw"
                :style="
                  this.in2 == false && (this.in1 || this.in3)
                    ? 'display:none !important'
                    : ''
                "
              >
                Every year, Kenta produces more than 10,000,000 plastic
                injection molding cores, and provides secondary processing to
                those as required.
                <span v-if="this.in2">
                  we always have numerus live production examples, such as
                  insertion of pins in quick release terminal blocks, or pin
                  insertion to allow bobbins to be fixed post winding. Kenta has
                  now become the market leader in China for the production of
                  these specialist items.
                </span>
              </p>
              <p
                v-if="this.$store.state.sw"
                :style="
                  this.in2 == false && (this.in1 || this.in3)
                    ? 'display:none !important'
                    : ''
                "
              >
                每年健大都會生產超過千萬件塑膠注塑膠芯，並提供二次加工，例如組裝端子和插針。
                <span v-if="this.in2">
                  完成塑膠膠芯的注塑之後，我們可以插入多類型的快速接線端子，也可以從多種不同尺寸的PIN針中進行選擇，以便BOBBIN在繞線操作之後進行固定，目前已成為中國市場塑膠產品後插針領導者之一。
                </span>
              </p>
              <br /><br /><br />
            </div>
            <div
              @click="
                () => {
                  this.in3 = !this.in3;
                  this.in1 = false;
                  this.in2 = false;
                }
              "
              :class="this.in3 ? 'k' : 'g'"
              :style="
                this.in3 == false && (this.in2 || this.in1)
                  ? 'background-color:rgb(184, 183, 183) !important'
                  : ''
              "
            >
              <h3 v-if="!this.$store.state.sw">
                Specialized in High-performance Engineered Resins
              </h3>
              <h3 v-if="this.$store.state.sw" class="t2">塑膠注塑機台</h3>
              <div
                :style="
                  this.in3 == false && (this.in1 || this.in2)
                    ? 'display:none !important; width:20%; text-angin:center !important;'
                    : ''
                "
              ></div>
              <img src="img/zsj.png" alt="" />
              <p
                v-if="!this.$store.state.sw"
                :style="
                  this.in3 == false && (this.in1 || this.in2)
                    ? 'display:none !important'
                    : ''
                "
              >
                Kenta owns and self maintains to the highest standards, more
                than 300 injection molding machines,
                <span v-if="this.in3">
                  all from tier 1 global manufacturers (e.g. Sumitomo & Fanuc);
                  Kenta has machines sizes ranging from 30 to 300 ton guage,
                  which allows us to produce components across a wide range of
                  conditions and materials. We have an extensive history of
                  injection molding high-performance engineered thermoplastics
                  resins (PEEK, PEI, PPS, LCP etc.), and partnering with
                  industry leading material suppliers to satisfy our customers
                  needs.
                </span>
              </p>
              <p
                v-if="this.$store.state.sw"
                :style="
                  this.in3 == false && (this.in1 || this.in2)
                    ? 'display:none !important'
                    : ''
                "
              >
                健大擁有超過400台注塑機，機台大小規格由30噸到350噸不等。
                <span v-if="this.in3">
                  精密結構件的生產是我們眾多優勢之一，我們擁有註塑高性能工程熱塑性樹脂（PEEK，PEI，PPS，LCP等）的悠久歷史，並與行業領先的材料供應商合作滿足客戶需求
                </span>
              </p>
              <br /><br /><br />
            </div>
          </div>
        </div>
        <div class="module2">
          <div class="title">
            <div>
              <span v-show="!this.$store.state.sw">Markets</span
              ><span v-show="this.$store.state.sw">服務</span>
            </div>
          </div>
          <div class="box">
            <div data-tooltip="Click the jump">
              <router-link :to="{ path: 'Markets?#Automotive' }">
                <img
                  class="img"
                  style="width: 17%"
                  src="img/automotive-fff.svg"
                  alt=""
                />
                <img
                  class="img2"
                  style="width: 20%"
                  src="img/automotive-000.svg"
                  alt=""
                />
                <span v-show="!this.$store.state.sw">Automotive </span
                ><span v-show="this.$store.state.sw">汽配車產品</span>
              </router-link>
            </div>
            <div data-tooltip="Click the jump">
              <router-link :to="{ path: 'Markets?#Medical' }">
                <img
                  class="img"
                  style="width: 17%"
                  src="img/medical-fff.svg"
                  alt=""
                />
                <img
                  class="img2"
                  style="width: 17%"
                  src="img/medical-000.svg"
                  alt=""
                />
                <span v-show="!this.$store.state.sw">Medical</span
                ><span v-show="this.$store.state.sw">醫療產品</span>
              </router-link>
            </div>
            <div data-tooltip="Click the jump">
              <router-link :to="{ path: 'Markets?#Network' }">
                <img
                  class="img"
                  style="width: 17%"
                  src="img/network-fff.svg"
                  alt=""
                />
                <img
                  class="img2"
                  style="width: 17%"
                  src="img/network-000.svg"
                  alt=""
                />
                <span v-show="!this.$store.state.sw">Network Components</span
                ><span v-show="this.$store.state.sw">網絡通信產品</span>
              </router-link>
            </div>
            <div alt="Click the jump">
              <router-link :to="{ path: 'Markets?#Molding' }">
                <img
                  class="img"
                  style="width: 17%"
                  src="img/electronic-fff.svg"
                  alt=""
                />
                <img
                  class="img2"
                  style="width: 17%"
                  src="img/electronic-000.svg"
                  alt=""
                />
                <span v-show="!this.$store.state.sw">Thermoset Molding </span
                ><span v-show="this.$store.state.sw">電木注塑</span>
              </router-link>
            </div>
            <div data-tooltip="Click the jump">
              <router-link :to="{ path: 'Markets?#Industrial' }">
                <img
                  class="img"
                  style="width: 17%"
                  src="img/industrial-fff.svg"
                  alt=""
                />
                <img
                  class="img2"
                  style="width: 17%"
                  src="img/industrial-000.svg"
                  alt=""
                />
                <span v-show="!this.$store.state.sw">Industrial</span>
                <span v-show="this.$store.state.sw">工控產品</span>
              </router-link>
            </div>
            <div data-tooltip="Click the jump">
              <router-link :to="{ path: 'Markets?#Food' }">
                <img
                  class="img"
                  style="width: 17%"
                  src="img/food-fff.svg"
                  alt=""
                />
                <img
                  class="img2"
                  style="width: 17%"
                  src="img/food-000.svg"
                  alt=""
                />
                <span v-show="!this.$store.state.sw">Food Grade</span>
                <span v-show="this.$store.state.sw">食品级</span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="module3">
          <h3>
            <span v-show="!this.$store.state.sw"> Kenta Background </span>
            <span v-show="this.$store.state.sw">關於健大</span>
          </h3>
          <div class="xiu"></div>
          <br />
          <div class="left">
            <p v-show="!this.$store.state.sw">
              Founded in 1985 in Hong Kong, KENTA has now grown into a
              multi-national corporation specializing in the R&D, tooling
              fabrication, and production of components in precision injection
              molding.
            </p>
            <p v-show="this.$store.state.sw">
              健大企業有限公司專業從事精密塑膠注塑産品的開發和製造，一九八五年於香港成立，下設東莞工廠和昆山工廠。公司在1998年到2005年期間先後通過了UL、ISO9001、ISO14001和TS16949認證，在2007年通過大眾、寶馬的二級供應商認證。
            </p>
            <br />
          </div>
          <br />
          <div class="right">
            <p v-show="!this.$store.state.sw">
              With multiple manufacturing sites in Mainland China, Kenta has
              achieved internationally recognized standards approvals, such as
              UL, ISO9001, ISO14001and IATF16949, as well as being fully
              certified as second tier supplier for global automotive
              corporations such as VW and BMW among many Kenta's philosophy is
              to provide precise, accurate and repeatable plastic injection
              products, through taking our customers through the entire product
              lifecycle; our focus on producing only the highest quality
              outcomes, enables our global customers to have the highest
              confidence in our ability to deliver.
            </p>
            <p v-show="this.$store.state.sw">
              公司以「開拓創新、追求卓越、系統管理、客戶滿意」為經營理念，秉承「以人為本、團隊合作、社會責任、環保理念」的企業文化，精心打造創新型管理團隊和技術團隊，不斷引進高端技術和高精密設備，持續提升企業綜合實力，成長爲一家集「産品研發、模具設計與製造、精密注塑成型、汽配零部件製造」爲一體的高科技企業，業務遍及全世界。
            </p>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div class="module4">
          <div class="left">
            <div></div>
            <img src="img/c1.png" alt="" />
          </div>
        </div>
      </div>
      <div class="foot">
        <div class="box1"></div>
        <div class="box">
          <h3 v-show="!this.$store.state.sw">Sustainability</h3>
          <h3 v-show="this.$store.state.sw">可持續性</h3>
          <h3></h3>
          <div
            class="btn"
            @click="
              () => {
                this.$router.push('about?#Sustainability');
              }
            "
          >
            <span v-show="!this.$store.state.sw">Read More</span
            ><span v-show="this.$store.state.sw">了解更多</span>
          </div>
        </div>
      </div>
      <div class="foot2">
        <div class="box">
          <div class="kuai">
            <div class="cont">
              <h3 v-show="!this.$store.state.sw">
                Quotation and Product Catalog
              </h3>
              <h3 v-show="this.$store.state.sw">免費報價和產品目錄</h3>
              <p v-show="!this.$store.state.sw">
                If you have any enquiries, you can contact customer service via
                <br />
                email to learn about the company’s products and services and
                <br />
                request a quotation
              </p>
              <p v-show="this.$store.state.sw">
                如有任何查詢，可透過電郵聯絡客服 <br />
                以了解公司的產品及服務索取報價
              </p>
              <div
                class="btn"
                @click="
                  () => {
                    this.$router.push('/contact');
                  }
                "
              >
                <span v-show="!this.$store.state.sw">Contact Us</span>
                <span v-show="this.$store.state.sw">聯絡我們</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="yd">
      <div class="banner">
        <img src="img/zbj.png" alt="" />
        <div class="mask">
          <div class="box">
            <h3 v-show="!this.$store.state.sw">
              Total Precision <br />
              Injection Solutions <br />
            </h3>
            <h3 v-show="this.$store.state.sw">
              我們專業從事 <br />
              精密塑膠注塑產品 <br />
              的開發和製造
            </h3>
            <div
              class="btn"
              @click="
                () => {
                  this.$router.push('/total');
                }
              "
            >
              <span v-show="!this.$store.state.sw">Read More</span
              ><span v-show="this.$store.state.sw">了解更多</span>
              <br />
              <img src="img/jt.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="module1">
        <div class="title">
          <h3 v-show="!this.$store.state.sw">Solutions</h3>
          <h3 v-show="this.$store.state.sw">業務範圍</h3>
        </div>
        <div
          @click="
            () => {
              this.in1 = !this.in1;
              this.in3 = false;
              this.in2 = false;
            }
          "
          :class="this.in1 ? 'k' : 'g'"
          :style="
            this.in1 == false && (this.in2 || this.in3)
              ? 'background-color:rgb(184, 183, 183) !important'
              : ''
          "
        >
          <h3 :class="this.$store.state.sw ? 't2' : ''">
            <h5 v-if="!this.$store.state.sw">
              Precision Plastic Mold Design and Manufacturing
            </h5>
            <h5 v-if="this.$store.state.sw" class="t2">精密塑膠模具設計</h5>
          </h3>
          <div
            :style="
              this.in1 == false && (this.in3 || this.in2)
                ? 'display:none !important;text-angin:center !important'
                : ''
            "
          ></div>
          <img style="width: 50vw" src="img/cc1.png" alt="" />
          <p
            v-if="!this.$store.state.sw"
            :style="
              this.in1 == false && (this.in3 || this.in2)
                ? 'display:none !important'
                : ''
            "
          >
            Whether it is a mold that requires high mold temperature molding or
            extremely tight tolerance molding.
            <span v-if="this.in1">
              KENTA can provide solutions to meet all your needs for precision
              plastic molds. Our capabilities include (not limited to fast and
              small molds with low product demand, or large-scale multi-cavity
              molds with high demand, and large composite molds such as stack
              molds, such as engineering test mold design, 3D printing, high
              mold temperature molding molds, and extremely tight tolerances.
              Molding molds, overmolding molds and insert molds, DFM, DOE,
              etc.</span
            >
          </p>
          <p
            v-if="this.$store.state.sw"
            :style="
              this.in1 == false && (this.in3 || this.in2)
                ? 'display:none !important'
                : ''
            "
          >
            無論是需求高模溫成型還是極嚴公差成型的模具。
            <span v-if="this.in1">
              健大都能提供滿足您所有精密塑膠模具所需求的解決方案。我們的能力包括（不限於產品需求量低的快速小型模具或者需求量大的多穴數以至疊模等大型複合模具，如工程試驗模具設計，3D打印，高模溫成型模具，需求極嚴公差成型模具，包膠模和嵌件模等模具，DFM，DOE）等</span
            >
          </p>
          <br /><br /><br />
        </div>
        <div
          @click="
            () => {
              this.in2 = !this.in2;
              this.in1 = false;
              this.in3 = false;
            }
          "
          :class="this.in2 ? 'k' : 'g'"
          :style="
            this.in2 == false && (this.in1 || this.in3)
              ? 'background-color:rgb(184, 183, 183) !important'
              : ''
          "
        >
          <h5 v-if="!this.$store.state.sw">
            Plastic Component Injection Molding
          </h5>
          <h5 v-if="this.$store.state.sw" class="t2">插針及鑲嵌包膠注塑</h5>
          <div
            :style="
              this.in2 == false && (this.in1 || this.in3)
                ? 'display:none !important'
                : ''
            "
          ></div>
          <img style="width: 50vw" src="img/cc2.png" alt="" />
          <p
            v-if="!this.$store.state.sw"
            :style="
              this.in2 == false && (this.in1 || this.in3)
                ? 'display:none !important'
                : ''
            "
          >
            Every year, Kenta produces more than 10,000,000 plastic injection
            molding cores, and provides secondary processing to those as
            required.
            <span v-if="this.in2">
              we always have numerus live production examples, such as insertion
              of pins in quick release terminal blocks, or pin insertion to
              allow bobbins to be fixed post winding. Kenta has now become the
              market leader in China for the production of these specialist
              items.
            </span>
          </p>
          <p
            v-if="this.$store.state.sw"
            :style="
              this.in2 == false && (this.in1 || this.in3)
                ? 'display:none !important'
                : ''
            "
          >
            每年健大都會生產超過千萬件塑膠注塑膠芯，並提供二次加工，例如組裝端子和插針。
            <span v-if="this.in2">
              完成塑膠膠芯的注塑之後，我們可以插入多類型的快速接線端子，也可以從多種不同尺寸的PIN針中進行選擇，以便BOBBIN在繞線操作之後進行固定，目前已成為中國市場塑膠產品後插針領導者之一。
            </span>
          </p>
          <br /><br /><br />
        </div>
        <div
          @click="
            () => {
              this.in3 = !this.in3;
              this.in1 = false;
              this.in2 = false;
            }
          "
          :class="this.in3 ? 'k' : 'g'"
          :style="
            this.in3 == false && (this.in2 || this.in1)
              ? 'background-color:rgb(184, 183, 183) !important'
              : ''
          "
        >
          <h5 v-if="!this.$store.state.sw">
            High-performance Engineered Resins
          </h5>
          <h5 v-if="this.$store.state.sw" class="t2">塑膠注塑機台</h5>
          <div
            :style="
              this.in3 == false && (this.in1 || this.in2)
                ? 'display:none !important; width:20%; text-angin:center !important;'
                : ''
            "
          ></div>
          <img style="width: 50vw" src="img/zsj.png" alt="" />
          <p
            v-if="!this.$store.state.sw"
            :style="
              this.in3 == false && (this.in1 || this.in2)
                ? 'display:none !important'
                : ''
            "
          >
            Kenta owns and self maintains to the highest standards, more than
            300 injection molding machines,
            <span v-if="this.in3">
              all from tier 1 global manufacturers (e.g. Sumitomo & Fanuc);
              Kenta has machines sizes ranging from 30 to 300 ton guage, which
              allows us to produce components across a wide range of conditions
              and materials. We have an extensive history of injection molding
              high-performance engineered thermoplastics resins (PEEK, PEI, PPS,
              LCP etc.), and partnering with industry leading material suppliers
              to satisfy our customers needs.
            </span>
          </p>
          <p
            v-if="this.$store.state.sw"
            :style="
              this.in3 == false && (this.in1 || this.in2)
                ? 'display:none !important'
                : ''
            "
          >
            健大擁有超過400台注塑機，機台大小規格由30噸到350噸不等。
            <span v-if="this.in3">
              精密結構件的生產是我們眾多優勢之一，我們擁有註塑高性能工程熱塑性樹脂（PEEK，PEI，PPS，LCP等）的悠久歷史，並與行業領先的材料供應商合作滿足客戶需求
            </span>
          </p>
          <br /><br /><br />
        </div>
      </div>
      <div class="module2">
        <div class="title">
          <h3>
            <span v-show="!this.$store.state.sw">Markets</span
            ><span v-show="this.$store.state.sw">服務</span>
          </h3>
        </div>
        <br />
        <div class="box">
          <div>
            <router-link :to="{ path: 'Markets?#tab1' }">
              <img
                class="img"
                style="width: 17%"
                src="img/automotive-fff.svg"
                alt=""
              />
              <span v-show="!this.$store.state.sw">Automotive</span
              ><span v-show="this.$store.state.sw">汽配車產品</span>
            </router-link>
          </div>
          <div>
            <router-link :to="{ path: 'Markets?#tab2' }">
              <img
                class="img"
                style="width: 17%"
                src="img/medical-fff.svg"
                alt=""
              />
              <span v-show="!this.$store.state.sw">Medical </span
              ><span v-show="this.$store.state.sw">醫療產品</span>
            </router-link>
          </div>
          <div>
            <router-link :to="{ path: 'Markets?#tab3' }">
              <img
                class="img"
                style="width: 17%"
                src="img/network-fff.svg"
                alt=""
              />
              <span v-show="!this.$store.state.sw">Network Communications</span
              ><span v-show="this.$store.state.sw">網絡通信產品</span>
            </router-link>
          </div>
          <div>
            <router-link :to="{ path: 'Markets?#tab5' }">
              <img
                class="img"
                style="width: 17%"
                src="img/electronic-fff.svg"
                alt=""
              />
              <span v-show="!this.$store.state.sw">Thermoset Molding </span
              ><span v-show="this.$store.state.sw">電木注塑</span>
            </router-link>
          </div>
          <div>
            <router-link :to="{ path: 'Markets?#tab4' }">
              <img
                class="img"
                style="width: 17%"
                src="img/industrial-fff.svg"
                alt=""
              />
              <span v-show="!this.$store.state.sw">Industrial Components</span>
              <span v-show="this.$store.state.sw">工控產品</span>
            </router-link>
          </div>
          <div>
            <router-link :to="{ path: 'Markets?#tab6' }">
              <img
                class="img"
                style="width: 17%"
                src="img/food-fff.svg"
                alt=""
              />
              <span v-show="!this.$store.state.sw">Food Graded</span>
              <span v-show="this.$store.state.sw">食品级</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="module3">
        <h3>
          <span v-show="!this.$store.state.sw"> Kenta Background </span>
          <span v-show="this.$store.state.sw">關於健大</span>
        </h3>
        <div class="left">
          <div class="xiu"></div>
          <p v-show="!this.$store.state.sw">
            Founded in 1985 in Hong Kong, KENTA has now grown into a
            multi-national corporation specializing in the R&D, tooling
            fabrication, and production of components in precision injection
            molding.
          </p>
          <p v-show="this.$store.state.sw">
            健大企業有限公司專業從事精密塑膠注塑産品的開發和製造，一九八五年於香港成立，下設東莞工廠和昆山工廠。公司在1998年到2005年期間先後通過了UL、ISO9001、ISO14001和TS16949認證，在2007年通過大眾、寶馬的二級供應商認證。
          </p>
          <br />
        </div>
        <br />
        <div class="right">
          <p v-show="!this.$store.state.sw">
            With multiple manufacturing sites in Mainland China, Kenta has
            achieved internationally recognized standards approvals, such as UL,
            ISO9001, ISO14001and IATF16949, as well as being fully certified as
            second tier supplier for global automotive corporations such as VW
            and BMW among many Kenta's philosophy is to provide precise,
            accurate and repeatable plastic injection products, through taking
            our customers through the entire product lifecycle; our focus on
            producing only the highest quality outcomes, enables our global
            customers to have the highest confidence in our ability to deliver.
          </p>
          <p v-show="this.$store.state.sw">
            公司以「開拓創新、追求卓越、系統管理、客戶滿意」為經營理念，秉承「以人為本、團隊合作、社會責任、環保理念」的企業文化，精心打造創新型管理團隊和技術團隊，不斷引進高端技術和高精密設備，持續提升企業綜合實力，成長爲一家集「産品研發、模具設計與製造、精密注塑成型、汽配零部件製造」爲一體的高科技企業，業務遍及全世界。
          </p>
          <br />
        </div>
      </div>
      <div class="module4">
        <div class="left">
          <img src="img/c1.png" alt="" />
        </div>
      </div>
      <div class="module5">
        <div class="box1"></div>
        <div class="box">
          <h3 v-show="!this.$store.state.sw">Sustainability</h3>
          <h3 v-show="this.$store.state.sw">可持續性</h3>
          <h3></h3>
          <div
            class="btn"
            @click="
              () => {
                this.$router.push('/about');
              }
            "
          >
            <span v-show="!this.$store.state.sw">Read More</span
            ><span v-show="this.$store.state.sw">了解更多</span>
          </div>
        </div>
      </div>
      <div class="module6">
        <div class="box">
          <div class="cont">
            <h3 v-show="!this.$store.state.sw">
              Quotation and Product Catalog
            </h3>
            <h3 v-show="this.$store.state.sw">免費報價和產品目錄</h3>
            <p v-show="!this.$store.state.sw">
              If you have any enquiries, you can contact customer service via
              <br />
              email to learn about the company’s products and services and
              <br />
              request a quotation
            </p>
            <p v-show="this.$store.state.sw">
              如有任何查詢，可透過電郵聯絡客服 <br />
              以了解公司的產品及服務索取報價
            </p>
            <div
              class="btn"
              @click="
                () => {
                  this.$router.push('/contact');
                }
              "
            >
              <span v-show="!this.$store.state.sw">Contact Us</span>
              <span v-show="this.$store.state.sw">聯絡我們</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sw: false,
      in1: false,
      in2: false,
      in3: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    wit(i) {
      document.querySelectorAll(".module1>.box>div")[
        i
      ].childNodes[2].childNodes[1].style.display = "block";
      document.querySelectorAll(".module1>.box>div").forEach((item) => {
        item.style.background = "url('/img/jb.png') no-repeat grey";
        item.style.backgroundSize = "2vw";
        item.style.backgroundPosition = "100% 100%";
      });
      if (i != this.in) {
        document.querySelectorAll(".module1>.box>div")[i].style.width = "60%";
        document.querySelectorAll(".module1>.box>div")[i].style.background =
          "url('/img/j2.png') no-repeat white 2vw";
        document.querySelectorAll(".module1>.box>div")[i].style.backgroundSize =
          "2vw";
        document.querySelectorAll(".module1>.box>div")[
          i
        ].style.backgroundPosition = "100% 100%";
        this.in = i;
      } else {
        document.querySelectorAll(".module1>.box>div").forEach((item) => {
          item.style.background = "url('/img/jb.png') no-repeat white";
          item.style.backgroundSize = "2vw";
          item.style.width = "25%";
          item.style.backgroundPosition = "100% 100%";
          item.childNodes[2].childNodes[1].style.display = "none";
        });
        this.in = 5;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.pc {
  .banner {
    height: 34vw;
    overflow: hidden;
    text-align: center;
    position: relative;
    .img1 {
      width: 100%;
      position: relative;
      top: -12vw;
    }
    .img2 {
      width: 100%;
      // height: 34vw;
    }
    .img3 {
      width: 100%;
      height: 35vw;
    }
    .mask {
      position: absolute;
      z-index: 9999;
      top: 0;
      background: rgba(15, 15, 15, 0.301);
      width: 100%;
      height: 100%;
      .box {
        box-sizing: border-box;
        text-align: left;
        position: absolute;
        top: 7vw;
        bottom: 0;
        left: 0;
        right: 0;
        width: 60%;
        margin: 0 auto;
        color: white;
        > h3 {
          font-size: 5vw;
          font-family: Roboto Condensed;
          font-style: normal;
          font-weight: bold;
          letter-spacing: 0.05em;
          text-transform: capitalize;
          text-shadow: 5px 4px 10px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
  .content {
    width: 62%;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    top: -4vw;
    z-index: 9999;
    .module1 {
      position: relative;
      img {
        width: 12vw;
        height: 9vw;
        margin: 1vw 0;
        transition: all 0.4s;
      }
      user-select: none;
      background: white;
      cursor: pointer;
      .k {
        overflow: hidden;
        width: 50% !important;
        background: url("../../public/img/j2.png") no-repeat !important;
        background-size: 1vw !important;
        background-position: 90% 95% !important;
        margin: 0 5vw;
        img {
          width: 100%;
          height: 11vw;
          margin: 1vw 0;
        }
        h3 {
          width: 100%;
          text-align: left;
          height: 12% !important;
        }
      }
      .g {
        width: 30% !important;
        overflow: hidden;
        padding: 1vw 2vw 6vw 2vw !important;
        background: url("../../public/img/jb.png") no-repeat white !important;
        background-size: 1vw !important;
        background-position: 90% 95% !important;
        overflow: hidden;
        h3 {
          width: 100%;
          text-align: left;
          height: 20% !important;
        }
        img {
          width: 100%;
          // height: 10vw;
          margin: 1vw 0;
        }
      }
      > h3 {
        font-family: Roboto;
        font-style: normal;
        height: 50%;
        font-weight: 900;
        font-size: 2vw;
        letter-spacing: 0.05em;
      }
      .box {
        width: 100%;
        margin-top: 3vw;
        display: flex;
        // flex-wrap: wrap;
        justify-content: space-between;
        > div {
          background: url("../../public/img/jb.png") no-repeat;
          background-size: 2vw;
          padding: 2.5vw 0vw;
          transition: all 0.4s;

          h3 {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            letter-spacing: 0.05em;
            font-size: 1.2vw;
            height: 20%;
          }
          .t2 {
            height: 3vw;
          }
          > div {
            width: 4vw;
            height: 0.15vw;
            background: red;
          }
          > p {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            margin-top: 1vw;
            font-size: 0.9vw;
            color: rgba(0, 0, 0, 0.442);
          }
        }
      }
    }
    .module2 {
      margin-top: 8vw;
      .title {
        position: relative;
        font-size: 1.8vw;
        border-bottom: red solid 4px;
        width: 4vw;
        color: black;
        font-family: Roboto;
        font-style: normal;
        font-weight: 900;
        letter-spacing: 0.05em;
        > div {
          position: absolute;
          width: 400px;
          bottom: 10px;
        }
      }
      .box {
        margin-top: 3vw;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        > div {
          margin-top: 1.5vw;
          background: gray;
          width: 32%;
          font-size: 1.2vw;
          height: 5vw;
          line-height: 5vw;
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          letter-spacing: 0.05em;
          box-sizing: border-box;
          padding-left: 2vw;
          img {
            vertical-align: middle;
            margin-right: 1vw;
          }
          .img2 {
            display: none;
          }
          span {
            color: white;
          }
        }
        > div:hover {
          background: white;
          .img2 {
            display: inline-block;
          }
          .img {
            display: none;
          }
          span {
            color: black;
            display: inline-block;
          }
        }
      }
    }
    .module3 {
      margin-top: 8vw;
      h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 900;
        font-size: 1.8vw;
        letter-spacing: 0.05em;
      }
      .xiu {
        margin: 1vw 0;
        background: red;
        height: 0.2vw;
        width: 5vw;
      }
      p {
        line-height: 150%;
        text-align: justify;
      }
      .left {
        font-size: 1.3vw;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.05em;
      }
      .right {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        letter-spacing: 0.05em;
        font-size: 1vw;
        color: gray;
      }
    }
    .module4 {
      display: flex;
      justify-content: space-between;
      > div {
        overflow: hidden;
      }
      .left {
        width: 100%;
        position: relative;
        div {
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(128, 128, 128, 0.144);
          top: 0;
        }
        img {
          width: 100%;
        }
      }
    }
  }
  .foot {
    width: 100%;
    box-sizing: border-box;
    background: url("../../public/img/kcx.jpg");
    background-size: 100%;
    position: relative;
    background-position: 100% 50%;
    height: 30vw;
    max-height: 630px !important;

    .box {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;

      position: absolute;
      width: 20%;
      height: 40%;
      text-align: center;
      h3 {
        color: white;
        font-family: Roboto;
        font-style: normal;
        letter-spacing: 0.05em;
        font-weight: 900;
        font-size: 2vw;
      }
      .btn {
        text-align: center;
        margin: 1em auto;
        background: white;
        width: 45%;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        line-height: 3vw;
        padding: 0 0.5vw;
        font-size: 1vw;
        cursor: pointer;
        color: black;
      }
    }
  }
  .foot2 {
    box-sizing: border-box;
    text-align: center;
    background: url("../../public/img/ft2.jpg");
    background-size: 100%;
    height: 30vw;
    position: relative;
    .box {
      .kuai {
        width: 90%;
        height: 100%;
        margin: 0 auto;
      }

      .cont {
        height: 100%;
        width: 100%;
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
        border-radius: 0.5vw;
        position: relative;
        z-index: 1;
        background: inherit;
        overflow: hidden;
        padding-top: 1vw;
      }

      .cont:before {
        content: "";
        position: absolute;
        background: transparent;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.253);
        backdrop-filter: blur(0.25vw);
        /* Google Chrome */
        /* 设置背景半透明黑色 */
        margin: -1vw;
      }

      position: absolute;
      width: 50%;
      height: 60%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      h3 {
        padding: 1vw 1vw 1vw 1vw;
        font-family: Roboto;
        font-style: normal;
        font-weight: 900;
        letter-spacing: 0.05em;
        font-size: 2vw;
        color: white;
        text-shadow: 0px 0px 5px black, 1px 2px 5px rgb(0 0 0, 0.5);
      }
      p {
        font-size: 1vw;
        padding: 2vw 1vw 1vw 1vw;
        font-family: Roboto;
        font-style: normal;
        // font-weight: 600;
        letter-spacing: 0.05em;
        color: white;
        width: 100%;
      }
      .btn {
        color: white;
        width: 20%;
        padding: 1vw;
        margin: 2vw auto;
        background: black;
        font-size: 1vw;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.05em;
        cursor: pointer;
      }
    }
  }
}
.yd {
  width: 100%;
  overflow: hidden;
  .banner {
    height: 100vw;
    overflow: hidden;
    text-align: center;
    position: relative;
    > img {
      width: 300%;
      position: relative;
      top: -50vw;
      left: -100vw;
      z-index: 0;
    }
    .mask {
      position: absolute;
      top: 0;
      background: rgba(15, 15, 15, 0.394);
      width: 100%;
      height: 100%;
      .box {
        box-sizing: border-box;
        text-align: left;
        position: absolute;
        top: 20%;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        margin: 0 auto;
        color: white;
        > h3 {
          font-size: 8vw;
          font-family: Roboto Condensed;
          font-style: normal;
          font-weight: bold;
          letter-spacing: 0.05em;
          text-transform: capitalize;
          text-align: center;
          text-shadow: 5px 4px 10px rgba(0, 0, 0, 0.455);
        }
        .btn {
          margin: 1em auto;
          width: 40%;
          font-size: 6vw;
          text-align: center;
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          cursor: pointer;
          img {
            width: 20%;
            animation-name: run;
            animation-duration: 1s;
            animation-iteration-count: infinite;
          }
        }
      }
    }
  }
  .module1 {
    width: 70%;
    background: white;
    margin: auto;
    position: relative;
    top: -20vw;
    font-size: 3.5vw;
    .title {
      font-family: Roboto;
      font-style: normal;
      font-weight: 900;
      font-size: 5vw;
    }
    h5 {
      font-size: 4vw;
    }
    > div {
      > div {
        width: 15%;
        background: red;
        height: 0.5vw;
        margin: 5vw 0;
      }
      padding: 10%;
    }
    .k {
      background: url("../../public/img/j2.png") no-repeat;
      background-size: 5vw !important;
      background-position: 95% 90% !important;
    }
    .g {
      background: url("../../public/img/jb.png") no-repeat;
      background-size: 5vw !important;
      background-position: 95% 90% !important;
    }
  }
  .module2 {
    width: 70%;
    margin: auto;
    position: relative;
    top: -10vw;
    .title {
      font-size: 5vw;
      padding: 0 8%;
    }
    .box {
      > div {
        background: gray;
        margin: 3.5vw 0;
        padding: 4vw 5vw;
        span {
          color: white;
          font-size: 4vw;
        }
        img {
          vertical-align: middle;
          margin-right: 2vw;
        }
      }
    }
  }
  .module3 {
    width: 70%;
    margin: auto;
    h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 900;
      font-size: 5.5vw;
    }
    .left {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 4vw;
      text-align: justify;
      letter-spacing: 0.04em;
    }
    .xiu {
      background: red;
      width: 20%;
      height: 0.5vw;
      margin: 5vw 0;
    }
    .right {
      p {
        color: gray;
        font-family: Roboto;
        font-size: 2.5vw;
        font-style: normal;
        font-weight: normal;
        line-height: 5vw;
        text-align: justify;
      }
    }
  }
  .module4 {
    margin-top: 10vw;
    width: 100%;

    div {
      text-align: center;
      margin: 5vw 3vw;
      img {
        width: 90%;
      }
    }
  }
  .module5 {
    width: 100%;
    box-sizing: border-box;
    background: url("../../public/img/kcx.jpg");
    background-size: 140% 100%;
    position: relative;
    height: 60vw;
    .box1 {
      width: 100%;
      height: 100%;
      background-image: radial-gradient(
        rgba(158, 158, 158, 0.308),
        rgb(63, 62, 62)
      );
      opacity: 0.8;
    }
    .box {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      width: 100%;
      height: 40%;
      text-align: center;
      h3 {
        color: white;
        font-family: Roboto;
        font-style: normal;
        letter-spacing: 0.05em;
        font-weight: 900;
        font-size: 6.5vw;
      }
      .btn {
        text-align: center;
        margin: 1em auto;
        background: white;
        width: 30%;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        line-height: 4vw;
        padding: 3vw 4vw;
        font-size: 4.5vw;
        cursor: pointer;
        color: black;
      }
    }
  }
  .module6 {
    box-sizing: border-box;
    text-align: center;
    background: url("../../public/img/ft2.jpg");
    background-size: 140%;
    height: 70vw;
    position: relative;
    .box {
      position: absolute;
      width: 80%;
      height: 70%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      .cont {
        box-sizing: border-box;
        height: 115%;
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
        border-radius: 0.5vw;
        position: relative;
        z-index: 1;
        background: inherit;
        overflow: hidden;
        padding: 3vw 0 3vw 0;
      }

      .cont:before {
        content: "";
        position: absolute;
        background: transparent;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.253);
        backdrop-filter: blur(0.25vw);
        /* Google Chrome */
        /* 设置背景半透明黑色 */
        margin: -1vw;
      }
      h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 900;
        letter-spacing: 0.05em;
        font-size: 5vw;
        color: white;
      }
      p {
        font-size: 3.5vw;
        font-family: Roboto;
        font-style: normal;
        letter-spacing: 0.05em;
        color: white;
        margin: 5vw 0;
      }
      .btn {
        color: white;
        width: 50%;
        padding: 2vw;
        margin: 1vw auto;
        background: black;
        font-size: 3.5vw;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.05em;
        cursor: pointer;
      }
    }
  }
}
@keyframes run {
  0% {
    transform: translateY(3vw) rotateZ(180deg);
  }
  50% {
    transform: translateY(0px) rotateZ(180deg);
  }
  100% {
    transform: translateY(3vw) rotateZ(180deg);
  }
}
</style>
