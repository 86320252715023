<template>
  <div id="app">
    <div class="pc pct">
      <div id="navpc">
        <img lazy src="img/logo.png" alt="" @click="
            () => {
              this.$router.push('/home');
            }
          " />
        <div class="right">
          <router-link to="/" exact><span v-show="this.sw">首頁</span><span v-show="!this.sw">Home</span></router-link>
          <router-link to="/innovation"><span v-show="this.sw">創新</span><span v-show="!this.sw">Innovation</span>
          </router-link>
          <router-link to="/total"><span v-show="this.sw">解決方案</span><span v-show="!this.sw">Solutions</span>
          </router-link>
          <router-link to="/Quality"><span v-show="this.sw">質量</span><span v-show="!this.sw">Quality</span>
          </router-link>
          <router-link to="/Facilities"><span v-show="this.sw">設備</span><span v-show="!this.sw">Facilities</span>
          </router-link>
          <router-link to="/MarKets"><span v-show="this.sw">服務行業</span><span v-show="!this.sw">Markets</span>
          </router-link>
          <router-link to="/about"><span v-show="this.sw">關於</span><span v-show="!this.sw">About Us</span></router-link>
          <router-link to="/contact"><span v-show="this.sw">聯絡</span><span v-show="!this.sw">Contact</span>
          </router-link>
          <div>
            <a @click="this.chenck" :class="this.sw ? '空白' : 'color'">
              EN
            </a>
            <a>|</a>
            <a @click="this.chenck2" :class="!this.sw ? '空白' : 'color'">中文
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="yd">
      <div id="nav">
        <div class="left">
          <img src="img/logo.png" alt="" @click="
              () => {
                this.$router.push('/home');
              }
            " />
        </div>
        <div class="right">
          <el-dropdown @command="handleCommand" trigger="click">
            <span class="el-dropdown-link">
              {{ this.lang }}<img src="img/jt5.png" alt="" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="EN">EN</el-dropdown-item>
              <el-dropdown-item command="中文">中文</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span @click="drawer = true">
            <img src="img/top.png" alt="" />
          </span>
          <el-drawer :visible.sync="drawer" :with-header="false">
            <div class="right3" @click="drawer = false">
              <router-link to="/" exact><span v-show="this.sw">首頁</span><span v-show="!this.sw">Home</span>
              </router-link>
              <br />
              <router-link to="/innovation"><span v-show="this.sw">創新</span><span v-show="!this.sw">Innovation</span>
              </router-link>
              <br />
              <router-link to="/total"><span v-show="this.sw">解決方案</span><span v-show="!this.sw">Solutions</span>
              </router-link>
              <br />
              <router-link to="/Quality"><span v-show="this.sw">質量</span><span v-show="!this.sw">Quality</span>
              </router-link>
              <br />
              <router-link to="/Facilities"><span v-show="this.sw">設備</span><span v-show="!this.sw">Facilities</span>
              </router-link>
              <br />
              <router-link to="/Markets"><span v-show="this.sw">服務行業</span><span v-show="!this.sw">Markets</span>
              </router-link>
              <br />
              <router-link to="/about"><span v-show="this.sw">關於</span><span v-show="!this.sw">About</span>
              </router-link>
              <br />
              <router-link to="/contact"><span v-show="this.sw">聯絡</span><span v-show="!this.sw">Contact</span>
              </router-link>
            </div>
          </el-drawer>
        </div>
      </div>
    </div>
    <router-view sw="this.sw" />
    <div id="footerpc">
      <div class="f_top">
        <div class="yuan" @click="this.top">
          <img src="img/arrow.png" alt="" />
        </div>
      </div>
      <div class="box">
        <div class="f_bottom">
          <img src="img/logo.png" alt="" />
          <table>
            <tr v-show="!this.sw">
              <th>Hong Kong</th>
              <td class="td1">
                <p>Flat B&C,11/F Winner Factory Building,</p>
                <p>55 Hung to Road, Kwun Tong</p>
                <p>Kowloon, Hong Kong</p>
              </td>
              <td class="td2">
                <p>FAX</p>
                <p>TEL</p>
                <p>EMAIL</p>
              </td>
              <td class="td3">
                <p>+852 2343 3650</p>
                <p>+852 2341 1597</p>
                <p>info@kenta.com.cn</p>
              </td>
            </tr>
            <tr v-show="this.sw">
              <th>香 港</th>
              <td class="td1">
                <p>
                  香港九龍官塘鴻圖道55號 <br />
                  幸運工業大廈11樓B&C室
                </p>
              </td>
              <td class="td2">
                <p>傳真</p>
                <p>電話</p>
                <p>電子郵件</p>
              </td>
              <td class="td3">
                <p>+852 2343 3650</p>
                <p>+852 2341 1597</p>
                <p>info@kenta.com.cn</p>
              </td>
            </tr>
            <tr v-show="!this.sw">
              <th>Dongguan</th>
              <td class="td1">
                <p>
                  He Xi District,Jin Xia, Cheung On,Dongguan City, Guangdong
                  China
                </p>
                <p>He Xi District,Jin Xia, Cheung On,Dongguan City,</p>
                <p>Guangdong China</p>
              </td>
              <td class="td2">
                <p>FAX</p>
                <p>TEL</p>
                <p>EMAIL</p>
              </td>
              <td class="td3">
                <p>+86 0769 8553 3429,8531 7199</p>
                <p>+86 0769 8553 6899,8553 6999</p>
                <p>info@kenta.com.cn</p>
              </td>
            </tr>
            <tr v-show="this.sw">
              <th>東莞</th>
              <td class="td1">
                <p>
                  中國廣東省東莞市長安鎮 <br />
                  錦廈河西工業區
                </p>
              </td>
              <td class="td2">
                <p>傳真</p>
                <p>電話</p>
                <p>電子郵件</p>
              </td>
              <td class="td3">
                <p>+86 0769 8553 3429,8531 7199</p>
                <p>+86 0769 8553 6899,8553 6999</p>
                <p>info@kenta.com.cn</p>
              </td>
            </tr>
            <tr v-show="!this.sw">
              <th>Kunshan</th>
              <td class="td1">
                <P>888 Xinmao Road,</P>
                <P>zhoushi Town, Kunshan City,</P>
                <P>Jiangsu China</P>
              </td>
              <td class="td2">
                <p>FAX</p>
                <p>TEL</p>
                <p>EMAIL</p>
              </td>
              <td class="td3">
                <P>+86 0512 5788 9889</P>
                <P>+86 0512 5788 9999</P>
                <P> info@kenta.com.cn </P>
              </td>
            </tr>
            <tr v-show="this.sw">
              <th>崑山</th>
              <td class="td1">
                <p>
                  中國江蘇省昆山市 <br />
                  周市鎮鑫茂路888號
                </p>
              </td>
              <td class="td2">
                <p>傳真</p>
                <p>電話</p>
                <p>電子郵件</p>
              </td>
              <td class="td3">
                <P>+86 0512 5788 9889</P>
                <P>+86 0512 5788 9999</P>
                <P> info@kenta.com.cn </P>
              </td>
            </tr>
            <tr v-show="!this.sw">
              <th>Shandong</th>
              <td class="td1">
                <P>Aerospace Industrial Park,Gaowu Road,</P>
                <P>Yingqian Street,High-tech Zone,</P>
                <P> Weifang City,Shandong Province</P>
              </td>
              <td class="td2">
                <p>EMAIL</p>
              </td>
              <td class="td3">
                <P> info@kenta.com.cn </P>
              </td>
            </tr>
            <tr v-show="this.sw">
              <th>山東</th>
              <td class="td1">
                <p>
                  山东省潍坊市高新区樱前<br />
                  街高五路 航空航天产业园
                </p>
              </td>
              <td class="td2">
                <p>電子郵件</p>
              </td>
              <td class="td3">
                <P> info@kenta.com.cn </P>
              </td>
            </tr>
          </table>
        </div>
        <hr />
        <p style="padding: 0.5vw 0; font-size: 0.8vw">
          <router-link to="/message"><span v-show="this.sw" @click="this.top">公告</span><span v-show="!this.sw"
              @click="this.top">Notice</span>
          </router-link>  | 苏ICP备16050706号-1  |  All right reserved by Kenta Enterprise
          Co.,Ltd.
        </p>
      </div>
    </div>
    <div id="footeryd">
      <div class="top">
        <div class="tiao">
          <el-backtop style="background: red" :bottom="100" :visibility-height="1500">
            <img src="img/jt.png" alt="" />
          </el-backtop>
        </div>
        <img src="img/logo.png" alt="" />
      </div>
      <div class="bottom">
        <div v-show="!this.sw">
          <h3>Hong Kong</h3>
          <div></div>
          <p>Flat B&C,11/F Winner Factory Building,</p>
          <p>55 Hung to Road, Kwun Tong,</p>
          <p>Kowloon, Hong Kong</p>
          <br />
          <p>FAX <span>(852) 2343 3650</span></p>
          <p>TEL <span>(852) 2341 1597</span></p>
          <p>EMAIL <span>info@kenta.com.cn</span></p>
        </div>
        <div v-show="this.sw">
          <h3>香 港</h3>
          <div></div>
          <p>
            香港九龍官塘鴻圖道55號 <br />
            幸運工業大廈11樓B&C室
          </p>
          <br />
          <p>傳真<span>+852 2343 3650</span></p>
          <p>電話<span>+852 2341 1597</span></p>
          <p>電子郵件 <span>info@kenta.com.cn</span></p>
        </div>
        <div v-show="!this.sw">
          <h3>Dongguan</h3>
          <div></div>
          <p>He Xi District, Jin Xia, Cheung On,</p>
          <p>Dongguan City,</p>
          <p>Guangdong China</p>
          <br />
          <p>
            FAX <span>+86 0769 8553 3429, 8531 7199</span>
            <br />
            TEL <span>+86 0769 8553 6899, 8553 6999</span>
            <br />
            EMAIL <span>info@kenta.com.cn</span>
          </p>
        </div>
        <div v-show="this.sw">
          <h3>東 莞</h3>
          <div></div>
          <p>
            中國廣東省東莞市長安鎮 <br />
            錦廈河西工業區
          </p>
          <br />
          <p>
            傳真 <span>+86 0769 8553 3429, 8531 7199</span>
            <br />
            電話 <span>+86 0769 8553 6899, 8553 6999</span>
            <br />
            電子郵件 <span>info@kenta.com.cn</span>
          </p>
        </div>
        <div v-show="!this.sw">
          <h3>Kunshan</h3>
          <div></div>
          <p>888 Xinmao Road,</p>
          <p>Zhoushi Town, Kunshan City,</p>
          <p>Jiangsu China</p>
          <br />
          <p>FAX <span>(+86 0512) 5788 9889</span></p>
          <p>TEL <span>(+86 0512) 5788 9999</span></p>
          <p>EMAIL <span>info@kenta.com.cn</span></p>
        </div>
        <div v-show="this.sw">
          <h3>崑 山</h3>
          <div></div>
          <p>
            中國江蘇省昆山市 <br />
            周市鎮鑫茂路888號
          </p>
          <br />
          <p>傳真 <span>(+86 0512) 5788 9889</span></p>
          <p>電話 <span>(+86 0512) 5788 9999</span></p>
          <p>電子郵件 <span>info@kenta.com.cn</span></p>
        </div>
        <div v-show="!this.sw">
          <h3>Shandong</h3>
          <div></div>
          <P>Aerospace Industrial Park,Gaowu Road,</P>
          <P>Yingqian Street,High-tech Zone,</P>
          <P> Weifang City,Shandong Province</P>
          <br />
          <p>EMAIL <span>info@kenta.com.cn</span></p>
        </div>
        <div v-show="this.sw">
          <h3>山 東</h3>
          <div></div>
          <p>
            山东省潍坊市高新区樱前 <br />
            街高五路 航空航天产业园
          </p>
          <br />
          <p>電子郵件 <span>info@kenta.com.cn</span></p>
        </div>
        <div style="
            width: 100%;
            height: 0.2vw;
            background: rgba(128, 128, 128, 0.408);
          " />
        <p style="padding: 1vw 0; font-size: 3vw">
          苏ICP备16050706号-1 <br />
          All right reserved by Kenta Enterprise Co.,Ltd.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sw: this.$store.state.sw,
      drawer: false,
      lang: "EN",
    };
  },
  methods: {
    top() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    chenck() {
      this.$store.commit("gai", false);
      this.sw = this.$store.state.sw;
    },
    chenck2() {
      this.$store.commit("gai", true);
      this.sw = this.$store.state.sw;
    },
    handleCommand(command) {
      this.lang = command;
      if (command == "EN") {
        this.$store.commit("gai", false);
        this.sw = this.$store.state.sw;
      } else {
        this.$store.commit("gai", true);
        this.sw = this.$store.state.sw;
      }
    },
  },
};
</script>
<style lang="less">
#app {
  background-color: #e8e8e8;
  background: url("../public/img/bj3.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;
    border-bottom: solid 4px transparent;
    &.router-link-active {
      border-bottom: solid 2px red;
    }
  }
  .pc {
    a {
      font-weight: bold;
      color: #2c3e50;
      text-decoration: none;
      border-bottom: solid 4px transparent;
      &.router-link-active {
        border-bottom: solid 2px red !important;
      }
    }
  }
  .color {
    color: red;
  }
}
* {
  margin: 0;
  padding: 0;
  font-family: Roboto;
}
.pc,
#footerpc {
  display: none;
}

// @media screen and(min-width: 1000px) and(max-width:1024px) {
//   html {
//     .mask {
//       .box {
//         width: 80% !important;
//       }
//     }
//     #navpc {
//       background: white;
//       display: flex;
//       align-items: center;
//       img {
//         width: 20%;
//         vertical-align: middle;
//         margin: 1vw 4vw 1vw 4vw !important;
//       }
//       > div {
//         display: inline-block;
//         > div {
//           display: flex;
//           align-items: center;
//           span {
//             font-size: 0.9vw;
//             vertical-align: middle;
//           }
//         }
//       }
//       .right {
//         width: 70% !important;
//         font-family: Roboto;
//         font-style: normal;
//         font-weight: normal;
//         display: flex;
//         justify-content: space-between;
//         a {
//           transition: all 0.1s;
//           font-size: 0.9vw;
//           cursor: pointer;
//           &:hover {
//             border-bottom: solid 4px red;
//           }
//         }
//       }
//     }
//     .content {
//       width: 80% !important;
//     }
//     .pc,
//     #footerpc {
//       display: block;
//     }
//     .yd,
//     #footeryd {
//       display: none;
//     }
//   }
// }
@media screen and(min-width: 1025px) {
  html {
    .pc,
    #footerpc {
      display: block;
    }
    .yd,
    #footeryd {
      display: none;
    }
  }
}
.pct {
  position: sticky;
  z-index: 9999999 !important;
  border-bottom: 1px solid red;
}
.pc {
  top: 0;
  #navpc {
    background: white;
    display: flex;
    align-items: center;
    img {
      width: 20%;
      vertical-align: middle;
      margin: 1vw 8vw 1vw 8vw;
    }
    > div {
      display: inline-block;
      > div {
        display: flex;
        align-items: center;
        span {
          font-size: 0.9vw;
          vertical-align: middle;
        }
      }
    }
    .right {
      width: 50%;
      display: flex;
      justify-content: space-between;
      a {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        transition: all 0.1s;
        font-size: 0.9vw;
        cursor: pointer;
        border-bottom: solid 2px transparent;
        &:hover {
          border-bottom: solid 2px red;
        }
      }
    }
  }
}
.el-dropdown-menu__item {
  width: 8vw;
  text-align: center;
  height: 10vw;
  list-style: none;
  padding: 0 2vw;
  margin: 0;
  font-size: 4vw;
  color: #606266;
  cursor: pointer;
  outline: 0;
  line-height: 10vw;
}
.yd {
  #nav {
    height: 12vw;
    background: white;
    box-sizing: border-box;
    padding: 2vw 0 2vw 2vw;
    display: flex;
    justify-content: space-between;
    .left {
      width: 60%;
      display: flex;
      align-items: center;
      img {
        width: 80%;
        vertical-align: middle;
      }
    }
    .right {
      .el-dropdown-link {
        overflow: hidden;
        font-size: 4vw;
        img {
          width: 4vw;
          transform: rotateZ(180deg);
        }
      }

      vertical-align: middle;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 3vw;
      font-weight: 900;
      img {
        width: 100%;
        vertical-align: middle;
      }
      .right3 {
        a {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          transition: all 0.1s;
          height: 10vw;
          line-height: 10vw;
          font-size: 4vw;
          display: block;
          text-align: center;
          cursor: pointer;
          &.router-link-active {
            color: white;
            background: black;
            border-bottom: solid 4px transparent;
          }
        }
      }
    }
  }
}
#footerpc {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  background: rgb(230, 229, 229);
  width: 100%;
  box-sizing: border-box;
  .box {
    width: 85%;
    margin: auto;
    > p {
      font-size: 1vw;
      color: gray;
    }
  }
  .f_top {
    position: relative;
    background: red;
    height: 2px;

    .yuan {
      position: absolute;
      top: -1.5vw;
      right: 5vw;
      background: red;
      width: 3vw;
      height: 3vw;
      text-align: center;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .f_bottom {
    img {
      width: 30%;
      margin: 3vw 0;
    }
    table {
      width: 100%;
      th {
        text-align: left;
        vertical-align: top;
        font-size: 2vw;
        padding: 1.5vw 2vw;
        width: 20%;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.05em;
      }

      td {
        padding: 1vw 0;
        vertical-align: top;
        color: gray;
        font-size: 0.8vw;
        line-height: 2vw;
        font-size: 1vw;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        letter-spacing: 0.05em;
        text-align: justify;
      }
      .td1 {
        width: 40vw;
      }
      .td2 {
        width: 10vw;
      }
      .td3 {
        width: 25vw;
      }
    }
  }
}
#footeryd {
  background: rgb(230, 229, 229);
  .tiao {
    width: 100%;
    height: 0.5vw;
    background: red;
    position: relative;
    margin-bottom: 10vw;
  }
  .top {
    text-align: center;
    img {
      width: 70%;
    }
  }
  .bottom {
    width: 70%;
    margin: 8vw auto 0 auto;
    p {
      color: rgba(128, 128, 128, 0.408);
      font-size: 1vw;
    }
    hr {
      margin: 10vw 0 2vw 0;
    }
    > div {
      margin: 15vw 0 0 0;
      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 5vw;
      }
      p {
        color: gray;
        font-size: 3vw;
        letter-spacing: 0.05em;
        line-height: 5vw;
        letter-spacing: 0.05em;
        span {
          float: right;
          width: 80%;
          text-align: left;
          font-size: 3vw;
        }
      }
      > div {
        width: 15%;
        height: 0.5vw;
        margin: 3vw 0;
        background: red;
      }
    }
  }
}
</style>
