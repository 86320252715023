import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BaiduMap from "vue-baidu-map";
import VueAwesomeSwiper from "vue-awesome-swiper";
import axios from "axios";
import $ from "jquery";
Vue.prototype.axios = axios;
Vue.prototype.$ = $;
Vue.use(VueAwesomeSwiper);
import "swiper/dist/css/swiper.min.css";
import {
  carousel,
  carouselItem,
  input,
  Drawer,
  button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Backtop,
  Carousel,
} from "element-ui";
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "KlrTTX297zUjR2sBGljOyY8bNoNpVsMZ",
});
Vue.use(Carousel);
Vue.use(Backtop);
Vue.use(carousel);
Vue.use(DropdownMenu);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(carouselItem);
Vue.use(input);
Vue.use(Drawer);
Vue.use(button);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
